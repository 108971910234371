<template>
  <div class="home-page scroll__common">
    <el-scrollbar
      style="height:100%"
      wrap-class="scrollbar-wrapper"
    >
      <div class="system-title">
        <img
          src="../assets/image/system-title.png"
          alt=""
        >
      </div>

      <div class="nav-box">
        <ul>
          <li class="active">首页</li>
          <li>产品介绍</li>
          <li>荣誉资质</li>
          <li>主营业务</li>
          <li>关于我们</li>
          <li>联系我们</li>
        </ul>
      </div>

      <div class="banner-box">
        <el-carousel
          :interval="5000"
          arrow="always"
        >
          <el-carousel-item
            v-for="item in bannerList"
            :key="item"
          >
            <el-image :src="item"></el-image>
          </el-carousel-item>
        </el-carousel>
      </div>

      <div class="comp-intro">
        <div class="comp-intro-body">
          <div class="home-title">公司简介</div>
          <div class="title-line"></div>

          <div class="comp-intro-text">合肥市智朋信息技术有限责任公司，成立于2019年08月23日，注册地位于安徽省合肥市，公司共有员工30多人，本科以上学历占90%，是一家新兴科技创新企业。公司拥有一支技术精湛、经验丰富的软件开发及技术服务团队，与省内外多所高校有着密切的合作和交流。公司为各行各业提供专业的技术服务，以卓越的服务品质、专业安全的技术服务实力，为不同行业的用户提供更高更优质的专业技术服务。</div>

          <div class="comp-img-box">
            <div>
              <img
                src="../assets/image/comp-img1.png"
                alt=""
              >
            </div>

            <div>
              <img
                src="../assets/image/comp-img2.png"
                alt=""
              >
            </div>
          </div>

        </div>
      </div>

      <div class="main-business">
        <div class="home-title-left">主营业务</div>
        <div class="title-line-left"></div>
        <div class="main-module-box">
          <div class="main-box box1">
            软件开发
          </div>
          <div class="main-box box2">
            大数据应用 <br /> 服务
          </div>
          <div class="main-box box3">
            人工智能应用 <br /> 开发
          </div>
          <div class="main-box box4">
            信息技术咨询 <br /> 服务
          </div>
          <div class="main-box box5">
            信息系统集成 <br /> 服务
          </div>
          <div class="main-box box6">
            信息系统运维 <br /> 服务
          </div>
        </div>
      </div>

      <div class="honor-box">
        <div class="home-title">荣誉资质-三体系</div>
        <div class="title-line"></div>

        <div class="honor-img-box">
          <div>
            <img
              src="../assets/image/honor-img1.png"
              alt=""
            >
          </div>

          <div>
            <img
              src="../assets/image/honor-img2.png"
              alt=""
            >
          </div>
          <div>
            <img
              src="../assets/image/honor-img3.png"
              alt=""
            >
          </div>
        </div>
      </div>

      <div class="copyright-box">
        <div class="home-title">荣誉资质-软件著作权</div>
        <div class="title-line"></div>

        <div class="copyright-img-box">
          <div>
            <img
              src="../assets/image/copyright-img1.png"
              alt=""
            >
          </div>

          <div>
            <img
              src="../assets/image/copyright-img2.png"
              alt=""
            >
          </div>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bannerList: [require('../assets/image/banner1.png'), require('../assets/image/banner2.png'), require('../assets/image/banner3.png')]
    }
  },
  created() {},
  mounted() {},
  methods: {}
}
</script>
<style scoped lang="less">
.home-page {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  .system-title {
    background: #fff;
    margin-left: 260px;
    box-sizing: border-box;
    img {
      margin-top: 15px;
      height: 73px;
    }
  }
  .nav-box {
    height: 80px;
    background-color: #3ba1ea;
    ul {
      width: calc(100% - 520px);
      margin: auto;
      list-style: none;
      padding: 0;
      box-sizing: border-box;
      li {
        height: 80px;
        display: inline-block;
        font-size: 20px;
        color: #fff;
        margin-right: 30px;
        padding: 0 50px;
        cursor: pointer;
        line-height: 80px;
        box-sizing: border-box;
      }
      li.active {
        background: rgba(255, 255, 255, 0.3);
      }
      li:hover {
        background: rgba(255, 255, 255, 0.3);
      }
    }
  }
  .banner-box {
    height: 555px;
    background: #fff;
    .el-image {
      width: 100%;
      height: 522px;
    }
  }

  .home-title {
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    letter-spacing: 3px;
    text-align: center;
    color: #3c3c3c;
    padding-top: 43px;
    box-sizing: border-box;
  }
  .title-line {
    margin: auto;
    margin-top: 10px;
    width: 130px;
    height: 5px;
    background: #f49310;
    border-radius: 3px;
    box-sizing: border-box;
  }

  .home-title-left {
    font-size: 32px;
    font-weight: bold;
    letter-spacing: 3px;
    color: #3c3c3c;
  }
  .title-line-left {
    margin-top: 10px;
    width: 130px;
    height: 5px;
    background: #f49310;
    border-radius: 3px;
  }
  .comp-intro {
    height: 900px;
    background: url('../assets/image/comp-intro-bg.png') no-repeat;
    background-size: 100% 100%;
    margin: auto;
    .comp-intro-body {
      width: calc(100% - 620px);
      height: 100%;
      margin: auto;
    }
    .comp-intro-text {
      text-align: center;
      margin-top: 30px;
      font-size: 24px;
      color: #2a2a2a;
      line-height: 46px;
      letter-spacing: 5px;
      padding: 0 10px;
      box-sizing: border-box;
    }
    .comp-img-box {
      margin-top: 50px;
      height: 422px;
      display: flex;
      justify-content: space-around;
      > div {
        flex: 1;
        width: 623px;
        height: 100%;
        margin: 20px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .main-business {
    padding: 50px 310px;
    height: 565px;
    background: #fff;
    .main-module-box {
      position: relative;
      margin-top: 70px;
      position: relative;
      height: 366px;
      padding: 0 30px;
      box-sizing: border-box;
      .main-box {
        position: absolute;
        width: 255px;
        height: 221px;
        color: #fff;
        font-size: 24px;
        text-align: center;
        line-height: 46px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .box1 {
        left: 0;
        top: 0;
      }
      .box2 {
        left: 200px;
        bottom: 0;
      }
      .box3 {
        left: 400px;
        top: 0;
      }
      .box4 {
        left: 600px;
        bottom: 0;
      }
      .box5 {
        left: 800px;
        top: 0;
      }
      .box6 {
        left: 1000px;
        bottom: 0;
      }
    }
    .main-module-box :nth-child(odd) {
      background: url('../assets/image/blue-box.png') no-repeat;
      background-size: 100% 100%;
    }
    .main-module-box :nth-child(even) {
      background: url('../assets/image/orange-box.png') no-repeat;
      background-size: 100% 100%;
    }
  }

  .honor-box {
    height: 737px;
    background: url('../assets/image/comp-intro-bg.png') no-repeat;
    background-size: 100% 100%;
    margin: auto;

    .honor-img-box {
      margin-top: 50px;
      padding: 0 410px;
      display: flex;
      justify-content: space-evenly;
      > div {
        flex: 1;
        img {
          height: 452px;
        }
      }
    }
  }
  .copyright-box {
    background: #fff;
    height: 737px;
    .copyright-img-box {
      margin-top: 50px;
      padding: 0 610px;
      display: flex;
      justify-content: space-evenly;
      > div {
        flex: 1;
        text-align: center;
        img {
          height: 452px;
        }
      }
    }
  }
}
</style>
<style lang="less">
.el-carousel {
  height: 522px !important;
  .el-carousel__container {
    height: 522px !important;
  }
}
</style>